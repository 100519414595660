import { render, staticRenderFns } from "./confirmData.vue?vue&type=template&id=2784c024&scoped=true&"
import script from "./confirmData.vue?vue&type=script&lang=js&"
export * from "./confirmData.vue?vue&type=script&lang=js&"
import style0 from "./confirmData.vue?vue&type=style&index=0&id=2784c024&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2784c024",
  null
  
)

export default component.exports