<template>
  <v-container
    id="register"
    style="margin-top: 45px"
    class="fill-height justify-center"
    tag="section"
  >
    <div
      class="text-center"
      v-if="configs && $route.query.partner_name != 'DXAInvestPrivate'"
    >
      <v-row justify="center">
        <v-col cols="5">
          <img
            alt="DXA"
            src="../../../../assets/dxa-white.png"
            height="70px"
            style="margin-right: 15px"
          />
        </v-col>
        <v-col
          v-if="configs && $route.query.partner_name != 'DXAInvestPrivate'"
          cols="1"
          style="display: flex; justify-content: center"
        >
          <v-divider
            vertical
            style="border-color: var(--dark); width: 1px"
          ></v-divider>
        </v-col>
        <v-col
          v-if="configs && $route.query.partner_name != 'DXAInvestPrivate'"
          cols="5"
        >
          <img
            alt="DXA"
            style="
              object-fit: contain;
              width: 174px;
              max-width: 100%;
              height: 70px;
            "
            :src="
              gs.get_photo_path(
                configs.image ? configs.image : configs.CompanyLogoPath
              )
            "
          />
        </v-col>
      </v-row>
    </div>
    <div class="text-center" style="width: 100%" v-else>
      <img src="../../../../assets/dxa-white.png" height="70px" alt="DXA" />
    </div>
    <v-row v-if="step == registerEnum.registerEmail">
      <p class="mx-auto mt-6 mb-2 px-3 text-center" style="font-size: 1.5rem">
        <strong>{{ $t("create_account") }}</strong>
      </p>
    </v-row>
    <v-row
      justify="center"
      align="center"
      style="width: 100%; margin: 0 auto; display: block"
    >
      <v-slide-y-transition appear>
        <v-card
          color="white"
          light
          max-width="100%"
          width="700"
          class="px-5 py-3"
          style="margin: 20px auto; display: block"
        >
          <v-card-text class="text-center">
            <v-row v-if="pageLoading" justify="center" align="center">
              <v-progress-circular indeterminate size="25" color="primary">
              </v-progress-circular>
            </v-row>
            <RegisterEmail
              v-else-if="step == registerEnum.registerEmail"
              @next="next_step"
              @back="back"
              :User="user"
            ></RegisterEmail>
            <RegisterInfo
              v-show="step == registerEnum.registerInfo"
              @next="next_step"
              @back="back"
              :User="user"
            ></RegisterInfo>
            <RegisterCarteira
              v-if="
                step > registerEnum.registerInfo &&
                step < registerEnum.dataConfirmation
              "
              @next="next_step"
              @back="back"
              :Loading="loading"
              :Categories="categories"
              :Step="step"
              :User="user"
            ></RegisterCarteira>
            <ConfirmData
              v-if="step == registerEnum.dataConfirmation"
              @next="next_step"
              @back="back"
              @register="register"
              :Step="step"
              :Loading="loading"
              :User="user"
            >
            </ConfirmData>
            <ValidateRegister
              v-if="step >= registerEnum.validateRegister"
              @next="next_step"
              @back="back"
              :Step="step"
              :User="user"
            ></ValidateRegister>
          </v-card-text>
        </v-card>
        <!-- </base-material-card> -->
      </v-slide-y-transition>
    </v-row>
    <v-row>
      <v-progress-linear
        class="mx-auto text-center"
        background-color="grey"
        color="primary"
        rounded
        style="max-width: 35%"
        height="7px"
        :value="(step * 100) / 8"
      ></v-progress-linear>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import RegisterEmail from "./tabs/RegisterEmail";
import RegisterInfo from "./tabs/RegisterInfo";
import RegisterCarteira from "./tabs/RegisterCarteira";
import ValidateRegister from "./tabs/ValidateRegister";
import ConfirmData from "./tabs/confirmData.vue";
import { RegisterSteps } from "@/shared/enums/RegisterSteps.js";
import { bankerRegisterWrongUrl } from "@/shared/helpers/bankerRegisterHelper";

export default {
  name: "PagesRegister",
  components: {
    RegisterEmail,
    RegisterInfo,
    RegisterCarteira,
    ValidateRegister,
    ConfirmData,
  },
  data: () => ({
    registerEnum: RegisterSteps,
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    pageLoading: false,
    step: 1,
    fillForm: true,
    categories: [],
    user: {
      JuridicalType: 0,
      Name: "",
      LastName: "",
      Email: "",
      UserPassword: "",
      SocialNumber: "",
      Birth: null,
      CelPhone: "",
      CompanyRevenue: 0,
      CompanyProfitability: 0,
      InvestIntended: 1,
      Terms: false,
      SignedPortfolio: false,
      MinESG: 0,
    },
    configs: null,
  }),
  async created() {
    this.get_categories();
    this.pageLoading = true;
    if (this.$route.query.partner_login != null) {
      var client = new ApiService();
      await client
        .getRequest(`user/get-external-user/${this.$route.query.partner_login}`)
        .then((result) => {
          if (!result.registered) {
            this.user.JuridicalType = result.user.juridicalType;
            this.user.Email = result.user.email;
            this.user.Name = result.user.name;
            this.user.LastName = result.user.lastName;
            this.user.Nationality = result.user.nationality;
            this.user.SocialNumber = result.user.socialNumber;
            if (this.gs.is_valid_date(result.user.birth)) {
              this.user.Birth = result.user.birth;
            }

            // Considerando que o telefone é br
            if (result.user.phone.indexOf("+55") > -1) {
              this.user.CelPhone = result.user.phone.slice(
                3,
                result.user.phone.length
              );
            } else {
              this.user.CelPhone = result.user.phone;
            }
          }
          this.configs = JSON.parse(result.configs);
        })
        .catch(function (err) {});
    } else if (Object.keys(this.$route.params).length >= 1) {
      var client = new ApiService();
      await client
        .getRequest(`partner/get/${this.$route.params.pathMatch}`)
        .then((result) => {
          this.configs = JSON.parse(result);
          this.user.BankerId = this.$route.query.banker_id;
        })
        .catch((err) => {
          if (err.status === 400) {
            bankerRegisterWrongUrl();
          }
        });
    } else if (Object.keys(this.$route.query).length >= 1) {
      var client = new ApiService();
      await client
        .getRequest(`partner/get/${this.$route.query.partner_name}`)
        .then((result) => {
          this.configs = JSON.parse(result);
          this.user.BankerId = this.$route.query.banker;
        })
        .catch((err) => {
          if (err.status === 400) {
            bankerRegisterWrongUrl();
          }
        });
    } else {
      bankerRegisterWrongUrl();
    }
    this.pageLoading = false;
  },
  computed: {},
  methods: {
    get_categories() {
      this.apiService
        .getRequest("category/list")
        .then((result) => {
          this.categories = JSON.parse(result.message).filter(
            (x) => x.ShownRegister == true
          );
        })
        .catch((error) => {});
    },
    register: async function () {
      this.error = null;
      var client = new ApiService();
      this.loading = true;
      if (
        this.user.CountryCode.callingCodes[0] &&
        this.user.CelPhone.substring(
          0,
          this.user.CountryCode.callingCodes[0].length
        ) != this.user.CountryCode.callingCodes[0]
      ) {
        this.user.CelPhone = `+${this.user.CountryCode.callingCodes[0]}${this.user.CelPhone}`;
      }
      this.user.SignedNda = true; // Temporário
      // if (this.user.JuridicalType === "Pessoa Física")
      //   this.user.JuridicalType = 0;
      // else this.user.JuridicalType = 1;

      if (Object.keys(this.$route.query).length === 0) {
        this.user.Type = 0;
        await client
          .postRequest("user/register", {
            User: this.user,
            lang: this.$i18n.locale,
          })
          .then((result) => {
            this.loading = false;
            this.step++;
          })
          .catch((err) => {
            this.user.CelPhone = this.user.CelPhone.slice(
              this.user.CountryCode.callingCodes[0].length + 1,
              this.user.CelPhone.length
            );
            this.$toast.error(this.$t(err.body.message));
            this.loading = false;
            // localStorage.removeItem("token");
            // localStorage.removeItem("user");
          });
      } else {
        await client
          .postRequest(
            "user/register/frompartner",
            {
              User: this.user,
              Partner: { UserId: this.configs.Id },
            },
            false
          )
          .then((result) => {
            this.user.Id = result;
            this.loading = false;
            this.step++;

            this.$toast.success(this.$t("code_sent"));
            this.$toast.warning(this.$t("talk_to_support"), { timeout: 10000 });
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_ocurred"));
            this.user.CelPhone = this.user.CelPhone.slice(
              this.user.CountryCode.callingCodes[0].length + 1,
              this.user.CelPhone.length
            );
            this.loading = false;
          });
      }
    },
    next_step() {
      this.step++;
    },
    back() {
      this.step--;
    },
  },
};
</script>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
