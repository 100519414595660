<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <strong>{{ $t("main_email") }}</strong>
        </p>
      </v-row>
      <v-row class="my-4">
        <v-text-field
          @keydown.space.prevent
          color="primary"
          outlined
          dense
          :rules="[required, accent_valid, email_valid]"
          v-model="User.Email"
          placeholder="email@email.com.br"
          data-test="NewUser:RegisterEmail:InputEmail"
        />
      </v-row>

      <v-btn
        data-test="NewUser:RegisterEmail:NextStep"
        type="submit"
        color="primary"
        :loading="loading"
        style="width: 150px"
        ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
      >
      <br />
      <label
        @click="$router.push('/auth')"
        style="
          margin-top: 30px;
          display: block;
          color: var(--primary) !important;
        "
        >{{ $t("login_button") }}</label
      >
    </v-form>
    <v-alert class="ma-2" v-if="alert" type="error">
      {{ alert }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "RegisterEmail",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
  }),
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    email_valid() {
      return (v) =>
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) || this.$t("format_invalid");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
  },
  methods: {
    checkForm: function () {
      this.alert = null;
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.apiService
          .postRequest(
            "user/check-email",
            { User: { Email: this.User.Email } },
            false
          )
          .then((resp) => {
            this.$emit("next");
            this.loading = false;
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_ocurred"));
            this.alert = this.$t("user_exists");
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
