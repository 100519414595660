<template>
  <div v-if="Step == 3">
    <div v-if="showThesis">
      <v-row class="mb-4">
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <strong>{{ $t("investment_thesis") }}</strong>
        </p>
      </v-row>
      <p class="text-left" style="color: var(--dark)">
        {{ $t("investment_thesis_1") }}
        <br /><br />
        {{ $t("investment_thesis_2") }}
      </p>
      <v-row
        justify="center"
        style="max-width: 275px; margin: 0 auto; display: flex"
      >
        <v-col class="col_btn_back px-1 py-1">
          <v-btn class="btn_back" color="primary" outlined @click="go_back"
            ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
          >
        </v-col>
        <v-col class="col_btn_next px-1 py-1">
          <v-btn
            class="btn_next"
            @click="showThesis = false"
            color="primary"
            data-test="NewUser:RegisterCarteira:NextStep"
            ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="mb-4">
        <p
          class="mx-auto"
          style="font-size: 1.5rem; color: var(--primary) !important"
        >
          <strong>{{ $t("administered_title") }}</strong>
        </p>
      </v-row>
      <p class="text-left" style="color: var(--dark)">
        {{ $t("administered_1") }}
      </p>
      <ul
        class="bulletPoints"
        style="text-align: left; color: var(--dark); font-weight: bold"
      >
        <li>
          {{ $t("wallet_company_size") }}
        </li>
        <li>
          {{ $t("wallet_company_industry") }}
        </li>
        <li>
          {{ $t("wallet_total_size") }}
        </li>
      </ul>
      <p class="text-left" style="color: var(--dark); margin-top: 20px">
        {{ $t("administered_2") }}
      </p>
      <v-row justify="center">
        <v-btn
          color="primary"
          outlined
          @click="showThesis = true"
          class="carteiraBtn"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >

        <v-btn
          @click="$emit('next')"
          color="primary"
          class="carteiraBtn"
          data-test="NewUser:RegisterCarteira:CustomizeWallet"
          ><span style="font-size: 1.1rem; white-space: pre-wrap">{{
            $t("customize_wallet")
          }}</span></v-btn
        >
      </v-row>
    </div>
  </div>
  <div v-else class="mx-5">
    <v-form
      @submit.stop.prevent="nextBtn_click()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row class="mb-4">
        <p
          class="mx-auto"
          style="font-size: 1.5rem; color: var(--primary) !important"
        >
          <strong>{{ $t("customize_wallet") }}</strong>
        </p>
      </v-row>
      <v-row class="text-left">
        <p style="font-size: 1.3rem; color: var(--dark); margin: 5px 0px">
          <strong v-if="Step == 4">{{ $t("company_revenue") }}</strong>
          <strong v-else-if="Step == 5">{{ $t("industry_sector") }}</strong>
          <strong v-else-if="Step == 6">{{
            $t("invest_intended_question")
          }}</strong>
        </p>
        <p
          v-if="Step == 4"
          style="font-size: 1.1rem; color: var(--dark); margin: 5px 0px"
        >
          {{ $t("company_revenue_description") }}
        </p>
      </v-row>
      <v-row>
        <v-radio-group
          v-if="Step == 4"
          style="height: 100%"
          center
          v-model="User.CompanyRevenue"
        >
          <v-radio :value="0">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("0_revenue") }}</span>
            </template>
          </v-radio>

          <v-radio :value="1" class="mt-3">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("1mil") }}</span
              ><span style="color: var(--primary) !important; margin: 0px 5px"
                >({{ $t("recomended") }})</span
              >
            </template>
          </v-radio>

          <v-radio :value="2" class="mt-3">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("5mil") }}</span>
            </template>
          </v-radio>
        </v-radio-group>

        <v-row v-else-if="Step == 5" class="mt-2" align="center">
          <v-col
            v-for="(c, i) in sectorOptions"
            :key="i"
            cols="12"
            md="6"
            class="my-0 py-0"
          >
            <v-checkbox
              class="my-0 py-0"
              v-model="c.Marked"
              :disabled="c.Disabled"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{ c.Text }}</span>
              </template></v-checkbox
            >
          </v-col>
          <v-col cols="12" md="12" class="my-0 py-0">
            <v-checkbox
              class="my-0 py-0 mx-auto text-center"
              v-model="noPreferenceSector"
              @change="noPreferenceSectorsCheckbox_changed"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("no_preference")
                }}</span>
                <span style="color: var(--primary) !important; margin: 0px 5px"
                  >({{ $t("recomended") }})</span
                >
              </template></v-checkbox
            >
          </v-col>
        </v-row>

        <v-radio-group
          v-else-if="Step == 6"
          center
          v-model="User.InvestIntended"
        >
          <v-radio :value="0">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("up_to_50k") }}</span>
            </template>
          </v-radio>
          <v-radio :value="1">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("up_to_100k") }}</span>
            </template>
          </v-radio>
          <v-radio :value="2">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("up_to_500k") }}</span>
            </template>
          </v-radio>
          <v-radio :value="3">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("up_to_1mil") }}</span>
            </template>
          </v-radio>
          <v-radio :value="4">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("above_1mil") }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row
        class="mt-1"
        justify="center"
        style="max-width: 275px; margin: 0 auto"
      >
        <v-col class="col_btn_back px-1 py-1">
          <v-btn class="btn_back" color="primary" outlined @click="go_back"
            ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
          >
        </v-col>

        <v-col class="col_btn_next px-1 py-1">
          <v-btn
            data-test="NewUser:RegisterCarteira:NextStepSizeCompany"
            class="btn_next"
            type="submit"
            color="primary"
            :loading="Loading"
            ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.bulletPoints li {
  margin: 10px 0px;
}

.btn_back {
  margin: 0px;
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  margin: 0px;
  width: 125px !important;
  height: 42px !important;
  margin-right: 0px !important;
}

.carteiraBtn {
  width: 225px;
  margin: 5px;
}

@media only screen and (max-width: 600px) {
  .carteiraBtn {
    margin: 5px 15px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "RegisterCarteira",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    noPreferenceSector: true,
    showThesis: true,
    noPreferenceCheckbox: false,
    sectorOptions: [],
    sliderDisabled: false,
  }),
  async created() {},
  props: {
    Loading: Boolean,
    Categories: Array,
    User: Object,
    Step: Number,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    get_sector_options() {
      var i;
      var user_sectors = [];
      this.sectorOptions = [];
      if (this.User.Sectors) {
        user_sectors = this.User.Sectors.split(";");
      }
      for (i = 0; i < this.Categories.length; i++) {
        this.sectorOptions.push({
          Text: this.Categories[i].Name,
          Id: this.Categories[i].Id,
          Marked: this.noPreferenceSector
            ? true
            : user_sectors.includes(this.Categories[i].Id.toString()),
          Disabled: this.noPreferenceSector,
        });
      }
    },
    go_back() {
      if (this.Step == 6) {
        this.get_sector_options();
      }
      this.$emit("back");
    },
    nextBtn_click() {
      if (this.Step == 4) {
        this.get_sector_options();
        this.$emit("next");
      } else if (this.Step == 6) {
        this.$emit("next");
        // this.get_founder_options();
      } else if (this.Step == 5) {
        this.$emit("next");

        var stringBuilder = "";
        var sectors_selected = this.sectorOptions.filter(
          (x) => x.Marked == true
        );
        for (var i = 0; i < sectors_selected.length; i++) {
          stringBuilder += sectors_selected[i].Id;
          if (i != sectors_selected.length - 1) {
            stringBuilder += ";";
          }
        }
        this.User.Sectors = stringBuilder;
      } else {
        this.$emit("next");
      }
    },

    noPreferenceCheckbox_changed() {
      if (this.noPreferenceCheckbox) {
        this.User.MinESG = 0;
        this.sliderDisabled = true;
      } else {
        this.sliderDisabled = false;
      }
    },
    noPreferenceSectorsCheckbox_changed() {
      if (this.noPreferenceSector) {
        this.sectorOptions = this.sectorOptions.map(function (x) {
          x.Marked = true;
          x.Disabled = true;
          return x;
        });
      } else {
        this.sectorOptions = this.sectorOptions.map(function (x) {
          x.Marked = false;
          x.Disabled = false;
          return x;
        });
      }
    },
    checkBox_changed(s, list) {
      if (list.includes(s)) {
        var index = list.indexOf(s);
        list.splice(index, 1);
      } else {
        list.push(s);
      }
    },
  },
};
</script>
