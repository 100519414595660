import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height justify-center",staticStyle:{"margin-top":"45px"},attrs:{"id":"register","tag":"section"}},[(_vm.configs && _vm.$route.query.partner_name != 'DXAInvestPrivate')?_c('div',{staticClass:"text-center"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c('img',{staticStyle:{"margin-right":"15px"},attrs:{"alt":"DXA","src":require("../../../../assets/dxa-white.png"),"height":"70px"}})]),(_vm.configs && _vm.$route.query.partner_name != 'DXAInvestPrivate')?_c(VCol,{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"cols":"1"}},[_c(VDivider,{staticStyle:{"border-color":"var(--dark)","width":"1px"},attrs:{"vertical":""}})],1):_vm._e(),(_vm.configs && _vm.$route.query.partner_name != 'DXAInvestPrivate')?_c(VCol,{attrs:{"cols":"5"}},[_c('img',{staticStyle:{"object-fit":"contain","width":"174px","max-width":"100%","height":"70px"},attrs:{"alt":"DXA","src":_vm.gs.get_photo_path(
              _vm.configs.image ? _vm.configs.image : _vm.configs.CompanyLogoPath
            )}})]):_vm._e()],1)],1):_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('img',{attrs:{"src":require("../../../../assets/dxa-white.png"),"height":"70px","alt":"DXA"}})]),(_vm.step == _vm.registerEnum.registerEmail)?_c(VRow,[_c('p',{staticClass:"mx-auto mt-6 mb-2 px-3 text-center",staticStyle:{"font-size":"1.5rem"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("create_account")))])])]):_vm._e(),_c(VRow,{staticStyle:{"width":"100%","margin":"0 auto","display":"block"},attrs:{"justify":"center","align":"center"}},[_c(VSlideYTransition,{attrs:{"appear":""}},[_c(VCard,{staticClass:"px-5 py-3",staticStyle:{"margin":"20px auto","display":"block"},attrs:{"color":"white","light":"","max-width":"100%","width":"700"}},[_c(VCardText,{staticClass:"text-center"},[(_vm.pageLoading)?_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"25","color":"primary"}})],1):(_vm.step == _vm.registerEnum.registerEmail)?_c('RegisterEmail',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.back}}):_vm._e(),_c('RegisterInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == _vm.registerEnum.registerInfo),expression:"step == registerEnum.registerInfo"}],attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.back}}),(
              _vm.step > _vm.registerEnum.registerInfo &&
              _vm.step < _vm.registerEnum.dataConfirmation
            )?_c('RegisterCarteira',{attrs:{"Loading":_vm.loading,"Categories":_vm.categories,"Step":_vm.step,"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.back}}):_vm._e(),(_vm.step == _vm.registerEnum.dataConfirmation)?_c('ConfirmData',{attrs:{"Step":_vm.step,"Loading":_vm.loading,"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.back,"register":_vm.register}}):_vm._e(),(_vm.step >= _vm.registerEnum.validateRegister)?_c('ValidateRegister',{attrs:{"Step":_vm.step,"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.back}}):_vm._e()],1)],1)],1)],1),_c(VRow,[_c(VProgressLinear,{staticClass:"mx-auto text-center",staticStyle:{"max-width":"35%"},attrs:{"background-color":"grey","color":"primary","rounded":"","height":"7px","value":(_vm.step * 100) / 8}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }