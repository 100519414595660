<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <strong>{{ $t("register") }}</strong>
        </p>
      </v-row>

      <v-row>
        <v-col cols="12" class="my-0 py-0">
          <p
            class="mb-0 text-left"
            style="font-size: 1.1rem; color: var(--dark)"
          >
            <strong>{{ $t("account_type") }}</strong>
          </p>
          <v-radio-group
            v-model="User.JuridicalType"
            row
            @change="juridical_type_changed"
          >
            <v-row justify="start" class="mx-1">
              <v-radio :value="0" class="mr-8">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{
                    $t("fisical_person")
                  }}</span>
                </template></v-radio
              >
              <v-radio :value="1">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{
                    $t("legal_person")
                  }}</span>
                </template></v-radio
              >
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          :md="User.JuridicalType == JuridicalTypeEnum.NaturalPerson ? 6 : 12"
          class="my-0 py-0"
        >
          <v-text-field
            data-test="NewUser:RegisterInfo:FirstName"
            color="primary"
            outlined
            dense
            :rules="[required]"
            :label="$t('name_register')"
            v-model="User.Name"
            class="mt-10"
            style="margin-top: 4px !important"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          style="margin-top: 4px"
          v-if="User.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-text-field
            data-test="NewUser:RegisterInfo:LastName"
            color="primary"
            outlined
            dense
            v-model="User.LastName"
            :label="$t('last_name')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="my-0 py-0">
          <v-combobox
            data-test="NewUser:RegisterInfo:Nationality"
            color="primary"
            dense
            outlined
            :disabled="User.JuridicalType == JuridicalTypeEnum.LegalPerson"
            :items="countries"
            item-text="name"
            return-object
            @change="nationality_code_changed"
            :rules="[required]"
            v-model="nationalitySelected"
            :label="$t('nationality')"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              <img
                :src="data.item.flag"
                style="width: 20px; height: auto"
                alt=""
              />
              <!-- HTML that describe how select should render selected items -->
              <span class="mx-2">{{ data.item.name }}</span>
            </template>
            <template slot="item" slot-scope="data">
              <img
                :src="data.item.flag"
                style="width: 20px; height: auto"
                alt=""
              />
              <span class="mx-2">{{ data.item.name }}</span>
            </template></v-combobox
          >
        </v-col>
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-text-field
            data-test="NewUser:RegisterInfo:Cpf"
            v-if="User.Nationality == 'Brazil' || User.Nationality == 'Brasil'"
            @keydown.space.prevent
            color="primary"
            dense
            outlined
            v-mask="
              User.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? '###.###.###-##'
                : '##.###.###/####-##'
            "
            type="tel"
            :rules="[required, social_number_valid]"
            v-model="User.SocialNumber"
            :label="
              User.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? $t('cpf')
                : $t('company_id')
            "
          />
          <v-text-field
            v-else
            @keydown.space.prevent
            color="primary"
            dense
            outlined
            type="tel"
            v-model="User.SocialNumber"
            :label="
              User.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? $t('passport')
                : $t('company_id')
            "
          />
        </v-col>
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-text-field
            data-test="NewUser:RegisterInfo:Birthday"
            dense
            outlined
            :class="field"
            v-model="date"
            :rules="[(v) => (!!v && v.length == 10) || required()]"
            placeholder="DD/MM/YYYY"
            v-mask="['##/##/####']"
            @blur="
              User.Birth = parseDate(date);
              check_date();
            "
            type="tel"
          />
          <p
            style="color: red; font-size: 11px"
            class="mb-4 mt-n4 ml-2"
            v-if="date == '' && fieldsRequired == true"
          >
            {{ $t("required") }}
          </p>
          <p
            style="color: red; font-size: 11px"
            class="mb-4 mt-n3 ml-3"
            v-if="!isDateValid"
          >
            {{ $t("valid_date") }}
          </p>
          <p
            style="color: red; font-size: 11px"
            class="mb-4 mt-n4 ml-2"
            v-else-if="!isDateAboveLegal"
          >
            {{ $t("min_18") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="my-0 py-0">
          <v-select
            color="primary"
            dense
            outlined
            :items="countries"
            item-text="name"
            return-object
            @change="country_code_changed"
            :rules="[required]"
            v-model="User.CountryCode"
            :label="$t('country_code')"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              <span class="mx-2">{{ data.item.callingCodes[0] }}</span>
            </template></v-select
          >
        </v-col>
        <v-col cols="12" md="8" class="my-0 py-0">
          <v-text-field
            data-test="NewUser:RegisterInfo:Cellphone"
            @keydown.space.prevent
            color="primary"
            dense
            outlined
            autocomplete="off"
            type="tel"
            v-mask="
              User.CountryCode && User.CountryCode.callingCodes[0] == '55'
                ? '(##) #####-####'
                : ''
            "
            :rules="[required, phone_valid]"
            v-model="User.CelPhone"
            :label="$t('celphone')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-text-field
            data-test="NewUser:RegisterInfo:Password"
            @keydown.space.prevent
            color="primary"
            outlined
            dense
            :label="$t('password')"
            v-model="User.UserPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[
              required,
              min_valid,
              uppercase_required,
              lowercase_required,
              number_required,
              special_required,
              accent_valid,
            ]"
            :type="show1 ? 'text' : 'password'"
            name="password"
            ref="user.UserPassword"
            @click:append="show1 = !show1"
          />
        </v-col>
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-text-field
            data-test="NewUser:RegisterInfo:ConfirmPassword"
            color="primary"
            outlined
            dense
            v-model="rePassword"
            :label="$t('re_password')"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[required, passwordsMatch]"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="my-0 py-0">
          <v-dialog persistent v-model="terms">
            <TermosPDFModal
              :key="terms"
              :Doc="use_terms_text"
              type="terms"
              :Header="$t('terms_use')"
              @agree="agree_terms"
              @decline="decline_terms"
            />
          </v-dialog>
          <v-checkbox
            data-test="NewUser:RegisterInfo:UseTerms"
            :rules="[required]"
            class="my-0 py-0"
            @click="open_terms"
            readonly
            v-model="User.Terms"
          >
            <template v-slot:label>
              <span style="color: var(--dark)"
                >{{ $t("read_agreed")
                }}<a class="ml-1" @click="open_terms"
                  ><strong
                    style="color: var(--dark); text-decoration: underline"
                    >{{ $t("terms_use") }}</strong
                  ></a
                ></span
              >
            </template></v-checkbox
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="my-0 py-0">
          <v-dialog persistent v-model="carteira">
            <CarteiraPDFModal
              :key="redraw"
              style="overflow-y: auto !important"
              :Doc="signed_portfolio_text"
              :BtnPlaceholder="$t('signed_portfolio')"
              :Header="$t('signed_portfolio_extended')"
              type="walletSignedPortfolio"
              @agree="agree_carteira"
              @decline="decline_carteira"
            />
          </v-dialog>
          <v-checkbox
            data-test="NewUser:RegisterInfo:WalletSignedPortfolio"
            :rules="[required]"
            class="my-0 py-0"
            @click="open_carteira"
            readonly
            v-model="User.SignedPortfolio"
          >
            <template v-slot:label>
              <span style="color: var(--dark)"
                >{{ $t("read_agreed")
                }}<a class="ml-1" @click="open_carteira"
                  ><strong
                    style="color: var(--dark); text-decoration: underline"
                    >{{ $t("signed_portfolio") }}</strong
                  ></a
                ></span
              >
            </template></v-checkbox
          >
        </v-col>
      </v-row>
      <v-row
        justify="center"
        style="max-width: 275px; margin: 0 auto; display: flex"
      >
        <v-col class="col_btn_next px-1 py-1">
          <v-btn
            data-test="NewUser:RegisterInfo:NextStep"
            class="btn_next"
            type="submit"
            color="primary"
            :loading="loading"
            ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-alert class="ma-2" v-if="alert" type="error">
      {{ $t(alert) }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
  margin: 0px;
}
.btn_next {
  margin: 0px;
  width: 125px !important;
  height: 42px !important;
  margin-right: 0px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import CarteiraPDFModal from "@/components/dxa/pdfModal/PdfModal";
import TermosPDFModal from "@/components/dxa/pdfModal/PdfModal";
import moment from "moment";
import countriesJson from "@/utils/countries.json";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";

export default {
  name: "RegisterInfo",
  components: {
    CarteiraPDFModal,
    TermosPDFModal,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    redraw: 0,
    loading: false,
    termsOpened: false,
    carteiraOpened: false,
    valid: true,
    lazy: false,
    countries: countriesJson,
    alert: null,
    show1: false,
    show2: false,
    carteira: false,
    terms: false,
    nationalitySelected: null,
    rePassword: "",
    menuDate: null,
    date: null,
    isDateValid: true,
    isDateAboveLegal: true,
    JuridicalTypeEnum,
  }),
  async created() {
    if (this.User.Birth) {
      this.date = moment(this.User.Birth).format("DD/MM/YYYY");
    }

    if (this.User.CountryCode == null) {
      this.User.CountryCode = this.countries.filter(
        (x) => x.name == "Brazil"
      )[0];
    }
    if (this.User.Nationality == null) {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == "Brazil"
      )[0];
      this.User.Nationality = "Brazil";
    } else {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == this.User.Nationality
      )[0];
    }
    //this.get_country_codes();
  },
  props: {
    User: Object,
  },
  computed: {
    signed_portfolio_text() {
      return [
        `<span>${this.$t("signed_portfolio_intro_1")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_1_1")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_1_2")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2_1")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2_2")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2_3")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2_3_1")}</span>`,
        `<span>${this.$t("signed_portfolio_intro_2_3")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_1_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_1_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_1_text_2_1")}</span>`,
        `<span style="text-decoration: underline">${this.$t(
          "signed_portfolio_1_text_2_2"
        )}</span>`,
        `<span>${this.$t("signed_portfolio_1_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_1_text_4")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_2_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_2_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_2_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_2_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_2_text_4")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_3_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_3_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_2_1")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_2_2")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_5")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_5_1")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_5_2")}</span>`,
        `<span>${this.$t("signed_portfolio_3_text_6")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_4_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_4_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_1_1")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_1_2")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_1_3")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_1")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_2")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_3")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_4")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_5")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_2_6")}</span>`,
        `<span>${this.$t("signed_portfolio_4_text_3")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_5_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_5_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_5_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_5_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_5_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_5_text_5")}</span>`,
        `<span>${this.$t("signed_portfolio_5_text_6")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_6_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_6_text_1_intro")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_1_1")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_1_2")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_1_3_header")}</span>`,
        `<p style="margin-left: 32px">${this.$t(
          "signed_portfolio_6_text_1_3_1"
        )}</p>`,
        `<p style="margin-left: 32px">${this.$t(
          "signed_portfolio_6_text_1_3_2"
        )}</p>`,
        `<p style="margin-left: 32px">${this.$t(
          "signed_portfolio_6_text_1_3_3"
        )}</p>`,
        `<p style="margin-left: 32px">${this.$t(
          "signed_portfolio_6_text_1_3_4"
        )}</p>`,
        `<p style="margin-left: 32px">${this.$t(
          "signed_portfolio_6_text_1_3_5"
        )}</p>`,
        `<span>${this.$t("signed_portfolio_6_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_3_1")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_3_2")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_31")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_6_text_5")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_7_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_7_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_7_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_7_text_3")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_8_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_8_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_8_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_8_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_8_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_8_text_5")}</span>`,
        `<span><strong>${this.$t("signed_portfolio_9_header")}</strong></span>`,
        `<span>${this.$t("signed_portfolio_9_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_9_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_9_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_9_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_9_text_5")}</span>`,
        `<span><strong>${this.$t(
          "signed_portfolio_10_header"
        )}</strong></span>`,
        `<span>${this.$t("signed_portfolio_10_text_1")}</span>`,
        `<span>${this.$t("signed_portfolio_10_text_2")}</span>`,
        `<span>${this.$t("signed_portfolio_10_text_3")}</span>`,
        `<span>${this.$t("signed_portfolio_10_text_4")}</span>`,
        `<span>${this.$t("signed_portfolio_10_text_5")}</span>`,
      ];
    },
    use_terms_text() {
      return [
        `<span><strong>${this.$t("terms_use_1_header")}</strong></span>`,
        `<span>${this.$t("terms_use_1_text_1")}</span>`,
        `<span>${this.$t("terms_use_1_text_2")}</span>`,
        `<span>${this.$t("terms_use_1_text_3")}</span>`,
        `<span>${this.$t("terms_use_1_text_4")}</span>`,
        `<span><strong>${this.$t("terms_use_2_header")}</strong></span>`,
        `<span>${this.$t("terms_use_2_text")}</span>`,
        `<span><strong>${this.$t("terms_use_3_header")}</strong></span>`,
        `<span>${this.$t("terms_use_3_text_1")}</span>`,
        `<span>${this.$t("terms_use_3_text_2")}</span>`,
        `<span style="text-decoration: underline">${this.$t(
          "terms_use_3_text_3"
        )}</span>`,
        `<span><strong>${this.$t("terms_use_4_header")}</strong></span>`,
        `<span>${this.$t("terms_use_4_text_1")}</span>`,
        `<span>${this.$t("terms_use_4_text_2")}</span>`,
        `<span>${this.$t("terms_use_4_text_3")}</span>`,
        `<span>${this.$t("terms_use_4_text_4")}</span>`,
        `<span><strong>${this.$t("terms_use_5_header")}</strong></span>`,
        `<span>${this.$t("terms_use_5_text")}</span>`,
        `<span><strong>${this.$t("terms_use_6_header")}</strong></span>`,
        `<span>${this.$t("terms_use_6_text_1")}</span>`,
        `<span>${this.$t("terms_use_6_text_2")}</span>`,
        `<span>${this.$t("terms_use_6_text_3")}</span>`,
        `<span>${this.$t("terms_use_6_text_4")}</span>`,
        `<span>${this.$t("terms_use_6_text_5")}</span>`,
      ];
    },
    passwordsMatch() {
      return () =>
        this.User.UserPassword === this.rePassword ||
        this.$t("pswds_dnt_match");
    },
    min18Years() {
      return () =>
        this.User.JuridicalType == this.JuridicalTypeEnum.LegalPerson ||
        (this.date != null &&
          moment().diff(
            moment([
              parseInt(this.date.split("/")[2]),
              parseInt(this.date.split("/")[1]) - 1,
              parseInt(this.date.split("/")[0]),
            ]),
            "years"
          ) >= 18) ||
        false;
    },
    phone_valid() {
      return () =>
        this.User.CountryCode.callingCodes[0] != "55" ||
        this.User.CelPhone.length == "(##) #####-####".length ||
        this.$t("phone_invalid");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    social_number_valid() {
      return (value) =>
        (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          .length == 11 &&
          this.vs.cpf_test(
            value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          )) ||
        (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          .length == 14 &&
          this.vs.cnpj_test(
            value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          )) ||
        this.$t("format_invalid");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("min_char");
    },
    special_required() {
      return (v) => /[*&$!@#?]/.test(v) || this.$t("special_required");
    },
    uppercase_required() {
      return (v) => /[A-Z]/.test(v) || this.$t("uppercase_required");
    },
    number_required() {
      return (v) => /[0-9]/.test(v) || this.$t("number_required");
    },
    lowercase_required() {
      return (v) => /[a-z]/.test(v) || this.$t("lowercase_required");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
  },
  methods: {
    agree_carteira() {
      this.User.SignedPortfolio = true;
      this.carteira = false;
    },
    decline_carteira() {
      this.User.SignedPortfolio = false;
      this.carteira = false;
    },
    agree_terms() {
      this.User.Terms = true;
      this.terms = false;
    },
    decline_terms() {
      this.User.Terms = false;
      this.terms = false;
    },
    valid_date() {
      return () =>
        (this.date &&
          parseInt(this.date.split("/")[2]) > 1600 &&
          moment([
            parseInt(this.date.split("/")[2]),
            parseInt(this.date.split("/")[1]) - 1,
            parseInt(this.date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date");
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    check_date() {
      if (!this.valid_date()) {
        this.isDateValid = false;
        return;
      }
      this.isDateValid = true;
      if (
        this.User.JuridicalType == this.JuridicalTypeEnum.NaturalPerson &&
        !this.min18Years()
      ) {
        this.isDateAboveLegal = false;
        return;
      }
      this.isDateAboveLegal = true;
    },
    country_code_changed() {
      if (this.User.CelPhone) {
        this.User.CelPhone = "";
      }
    },
    nationality_code_changed() {
      if (this.nationalitySelected.name) {
        this.User.Nationality = this.nationalitySelected.name;
      }
    },
    cnpj_test(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
    carteira_changed() {
      if (!this.carteiraOpened) {
        this.open_carteira();
      }
    },
    open_carteira() {
      this.redraw++;
      this.User.SignedPortfolio = false;
      this.carteira = true;
      this.carteiraOpened = true;
    },
    get_carteira() {
      var env_url = process.env.VUE_APP_BASE_URL;
      return env_url + `Documents/carteira_assinada_${this.$i18n.locale}.pdf`;
    },
    get_terms() {
      var env_url = process.env.VUE_APP_BASE_URL;
      return env_url + `Documents/terms_of_use_${this.$i18n.locale}.pdf`;
    },
    open_terms() {
      this.User.Terms = false;
      this.terms = true;
      this.termsOpened = true;
    },
    juridical_type_changed() {
      if (this.User.JuridicalType == this.JuridicalTypeEnum.LegalPerson) {
        this.nationalitySelected = this.countries.filter(
          (x) => x.name == "Brazil"
        )[0];
        this.User.Nationality = "Brazil";
      }
    },

    checkForm: function () {
      this.alert = null;
      if (this.loading) {
        return;
      }

      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.apiService
          .postRequest(
            `user/check-social-number`,
            {
              SocialNumber: this.User.SocialNumber,
            },
            false
          )
          .then((resp) => {
            this.$emit("next");
            this.loading = false;
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_ocurred"));
            this.alert = this.$t("social_number_exists");
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
