<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <strong>{{ $t("confirm_data") }}</strong>
        </p>
      </v-row>
      <div style="width: 200px; display: inline-table">
        <v-row class="my-4">
          <v-text-field
            @keydown.space.prevent
            color="primary"
            dense
            v-mask="['##############']"
            :rules="[required]"
            :disabled="disabled"
            hide-details
            v-model="User.CelPhone"
          >
            {{ this.User.CelPhone }}</v-text-field
          >
        </v-row>
        <v-row class="my-4">
          <v-text-field
            @keydown.space.prevent
            color="primary"
            dense
            :rules="[required]"
            :disabled="disabled"
            v-model="User.Email"
            >{{ this.User.Email }}</v-text-field
          >
        </v-row>
        <a v-if="changeToSave == false" @click="alterar">{{ $t("change") }}</a>
        <a v-else @click="isEmailValid">{{ this.$t("save") }}</a>
      </div>
      <v-row
        class="mt-1"
        justify="center"
        style="max-width: 275px; margin: 0 auto"
      >
        <v-col class="col_btn_back px-1 py-1">
          <v-btn
            class="btn_back"
            color="primary"
            outlined
            @click="$emit('back')"
            ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
          >
        </v-col>

        <v-col class="col_btn_next px-1 py-1">
          <v-btn
            data-test="NewUser:ConfirmData:NextStepConfimData"
            :disabled="disabled_next"
            class="btn_next"
            type="submit"
            color="primary"
            :loading="Loading"
            ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-alert class="ma-2" v-if="alert" type="error">
      {{ alert }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  margin: 0px;
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  margin: 0px;
  width: 125px !important;
  height: 42px !important;
  margin-right: 0px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "ConfirmData",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    disabled: true,
    disabled_next: false,
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    email_msg: null,
    findArroba: null,
    number_msg: null,
    changeToSave: false,
  }),
  async created() {},
  props: {
    Loading: Boolean,
    User: Object,
    Step: Number,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isEmailValid() {
      this.apiService
        .postRequest(
          "user/check-email",
          { User: { Email: this.User.Email } },
          false
        )
        .then((resp) => {
          this.changeToSave = false;
          this.disabled = true;
          this.disabled_next = false;
        })
        .catch((error) => {
          this.disabled_next = true;

          this.$toast.error(this.$t("user_exists"), { timeout: 10000 });
        });
    },
    alterar() {
      this.changeToSave = true;
      this.disabled = false;
      this.disabled_next = true;
    },

    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("register");
      }
    },
  },
};
</script>
